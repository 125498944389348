@import "common.pcss";

.main-visual {
  position: relative;
  height: 42.7rem;
  overflow: hidden;
  @media (min-width: 600px) {
    height: 70.2rem;
  }

  &__player {
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translateX(-50%);
    @media (min-width: 600px) {
      min-width: calc((702 / 862) * 1920 * .1rem);
    }

    &-inner {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 0;
      padding-bottom: calc(854 / 640 * 100%);
      overflow: hidden;
      transform: translate(-50%, -50%);
      @media (min-width: 600px) {
        padding-bottom: calc(862 / 1920 * 100%);
      }
    }

    &-itself {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: opacity .5s ease-out;
      &.active {
        opacity: 1;
      }
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  & .container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &__headline {
    margin: 0;
    color: #fff;
    line-height: 2.8rem;
    font-size: 2rem;
    font-weight: 300;
    text-align: center;
    @media (min-width: 600px) {
      line-height: 5.5rem;
      font-size: 4rem;
    }
  }
}
