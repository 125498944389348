:root {
  font-size: calc(10 / 320 * 100vw);
  @media (min-width: 600px) {
    font-size: calc(10 / 960 * 100vw);
  }
  @media (min-width: 960px) {
    font-size: 10px;
  }
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Noto Serif JP', serif;
  font-size: 1.4rem;
}

.site-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 6;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 5rem;
  background-color: #fff;
  box-shadow: 0 .3rem .6rem rgba(0, 0, 0, .16);
  @media (min-width: 600px) {
    height: 8rem;
  }

  &__dummy {
    margin: 0;
    color: rgba(0, 0, 0, .56);
    font-size: inherit;
    font-weight: inherit;
  }
}

.main {
  padding-top: 5rem;
  @media (min-width: 600px) {
    padding-top: 8rem;
  }
}

.container {
  margin: auto;
  padding: 0 1.6rem;
  @media (min-width: 600px) {
    max-width: 96rem;
    padding: 0 2.4rem;
  }
}
